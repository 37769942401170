// 79909
@import '../helpers/componentHeader';

//58165
.banner-image-with-bullet-overlay {
    padding-top: 80px;
    background-color: $white;
    @include media-breakpoint-down(sm) {
        padding-top: 0px;
    }
    .bannerwith-bulletoverlay {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {

        }
        @include media-breakpoint-down(sm) {
            background: none!important;
        }
        &__bg {
            background: rgba(0, 0, 0, 0.2);
            @include media-breakpoint-down(sm) {
                background: none;
            }
        }

        .col-banner {
            &__left {
                @include media-breakpoint-up(md) {
                    min-height: 560px;
                    background: none !important;
                } 
                
                @include media-breakpoint-down(xs) {
                    background-size: cover;
                    height: 200px;
                }
                .banner-content__left {
                    @include media-breakpoint-down(sm) {
                        margin: 25px;
                    }
                    .banner-heading {
                        color: $white;
                        margin: 0px;
                        font-family: $secondary-font-Regular;
                        font-size: 100px;
                        line-height:120px;
                        letter-spacing: 0px;
                        max-width: 400px;
                        font-weight: 600;
                        margin: 0px 0px -19px -2px;
                        @include media-breakpoint-down(lg) {
                            font-size: 82px;
                            line-height: 96px;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 42px;
                            line-height: 48px;
                            margin:0px 0px -4px;
                            max-width: 150px;
                        }
                    }
                }
            }
            &__right {
                min-height: 250px;

                @include media-breakpoint-up(md) {
                    min-height: 560px;
                }
                .banner-content__right {
                    background-color: $white;
                    padding: 35px 40px 6px;
                    margin-bottom: 30px;
                    min-height: 365px;
                    width: 100%;
                    @include media-breakpoint-down(sm) {
                        margin: -30px 15px 0px;
                        padding: 30px 0px 40px;
                    }
        
                    .card {
                        margin-bottom: 30px;
                        .card-body {
                            display: flex;
                            flex-flow: row;

                            @include media-breakpoint-down(lg) {
                                padding: 0px 15px 0px;
                            }
            
                            @include media-breakpoint-down(sm) {
                                flex-flow: column;
                                text-align: center;
                            }
            
                            .photo-tile-figure {
                                padding: 5px 20px 0px 0px;
                                margin: 0px;
                                text-align: center;
                            @include media-breakpoint-up(md) {
                                max-width: 62px;
                                flex: 0 0 62px;
                            }
                            @include media-breakpoint-down(sm) {
                                padding: 10px 20px 20px;
                            }
                                .photo-tile-image {
                                    max-width: 42px;
                                }
                            }
            
                            .card-text {
                                font-family: $primary-font-semibold;
                                color: $primary-darkest;
                                font-size: 18px;
                                line-height: 32px;
                                color: $primary-darkest;
                                @include media-breakpoint-down(sm) {
                                    font-size: 16px;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// 58165
